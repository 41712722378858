import React, { useEffect, useState } from 'react';
import { getAccount } from '../Hooks/useAccounts';
import { getPoolInfoLimit, getPoolLength, getUser } from '../Hooks/useStake';
import Footer from './Footer';
import Header from './Header';
import LockedStackCont from './LockedStackCont';
import logoicon from '../Assets/images/logoicon.png'
import circle from "../Assets/images/circle.png";

const LockedStake = () => {
    const [poolLimit, setPoolLimit] = useState([]);
    const [poolLength, setPoolLength] = useState(0);
    const [accountInfo, setAccountInfo] = useState(getAccount());

    useEffect(() => {
        getData();
    })


    const getData = async () => {


        var data = await getPoolLength()
        if (data) setPoolLength(data);
        var pool = await getPoolInfoLimit(0, data)

        if (pool) {
            setPoolLimit(pool);
        }


    }
    return (
        <div className='lockedstake'>

            <Header />
            <div className='allsection'>
                <div className='container container-theme mt-4'>
                    {/* <div className='showloader minwidth-534 text-center' id="loader_div">
                <div className='loader ' >
                    <img src={logoicon} className="spin" />
                    
                </div>

            </div> */}
                    <div className='br-14 bgtheme text-dark p-sm-5 p-4 text-center bannerbox d-flex align-items-center justify-content-center'>
                        <div class="lines">
                            <div class="line1"></div>
                            <div class="line1"></div>
                            <div class="line1"></div>
                            <div class="line1"></div>
                        </div>
                        <span className='greengradientleft'></span>
                        <span className='greengradientright'></span>
                        <img className='img-fluid circlelanch' src={circle} alt='alirobo' />
                        <h1 className='innerheading'><span className='titlespan'>Loc</span>ked Staking</h1>
                    </div>
                    <div className='row mb-5'>

                        {poolLimit
                            && poolLimit.map((val, index) => 
                            (index == 0 || index== 1  || index == 2   || index == 4  || index == 5 ?
                            <LockedStackCont datas={val} pid={index} accountInfo={accountInfo} /> : <></>)
                            )}


                       

                    </div>


                </div>
            </div>
            <Footer />
        </div>
    )
}

export default LockedStake