import React, { useEffect, useState } from 'react';
import Footer from './Footer'
import Header from './Header';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import robo from "../Assets/images/robo1.svg";
import lightrobo from "../Assets/images/roboleft.png";
import downrobo from "../Assets/images/scanrobo.png";
import coinrobo from "../Assets/images/Background.svg";
import sitrobo from "../Assets/images/sitrobo.svg";

import robos from "../Assets/images/robos.svg";
import alien from "../Assets/images/alien.svg";
import siderobo from "../Assets/images/siderobo.svg";
import leftrobo from "../Assets/images/leftrobo.svg";

import crossline from "../Assets/images/cross-line-1.png";
import ellipse from "../Assets/images/Ellipse.svg";
import polygon from "../Assets/images/Polygon.svg";
import polygon2 from "../Assets/images/polygon2.png";
import leftside from "../Assets/images/leftside.png";

import ploylight from "../Assets/images/ploylight.png";

import lastline from "../Assets/images/lastline.png"

import circle from "../Assets/images/circle.png";

import greenrectangle from "../Assets/images/greenrectangle.png"

import AOS from 'aos';
import 'aos/dist/aos.css';

import Typed from 'react-typed';
import { getFeatureData,getRoadmapdesData,getFeaturedesData,getAboutData ,getHomepage, getHomeimage} from '../Hooks/UseBackend';

// import backurl from '../../../adminpanel/src/lib/config';
import { backurl } from '../Config/env';

const Home = () => {

    const [featuredata,setfeaturedata] = useState([])
    const [roadmapdata,setroadmapdata] = useState([])
    console.log("roadmapdata_roadmapdataroadmapdata",roadmapdata);
    const [featuredesdata,setfeaturedesdata] = useState([])
    console.log("featuredesdata_V_featuredesdataV",featuredesdata);
    const [aboutdata,setaboutdata] = useState([])
    console.log("aboutdata_aboutdata_aboutdata",aboutdata);
    const [Homepage,setHomepage] = useState([])
    const [Homeimage,setHomeimage] = useState([])


    



    useEffect(() => {
        getData()
        AOS.init();
    }, [])



    const getData = async () => {
        var feature = await getFeatureData();
        console.log("feature_feature_feature_V", feature);
        setfeaturedata(feature)
        var roadmapdes = await getRoadmapdesData();
     
        setroadmapdata(roadmapdes)
        var featruedes = await getFeaturedesData();
        setfeaturedesdata(featruedes)
        var data = await getAboutData();
        console.log("datadata_data",data);
        setaboutdata(data)
        var datas = await getHomepage();
        setHomepage(datas)
        console.log("datadata_data",data);
        var datas = await getHomeimage();
        setHomeimage(datas)



    }

    const options ={
        loop:true,
        margin:10,
        autoplay:true,
        autoplaySpeed:5000,
        autoplayHoverPause:true,
        autoplayHoverPause:true
    }

    const responsive1 = {

        0: {
            items: 1,
        },
        424: {
            items: 1,
        },
        576: {
            items: 1,
        },
        768: {
            items: 1,
        },
        1200: {
            items: 1,
        },
    }

    // const cmsFilter = async(data) => {
    //     try {
    //     const returnData = featuredata?.filter((el) => el.identifier == data )
    //     if(returnData){
    //         console.log("returnDatareturnDatareturnData",returnData);
    //         return returnData
    //     }
    //     } catch (e) {
    //       console.log('cmsFilter',e);  
    //     }
    // }

    return (
        <>
            <div className='land'>
                <Header />

                <div className='uidev'>

                    <div className='container container-theme'>
                        <section className='banner firstsection'>
                            <div class="lines">
                                <div class="line1"></div>
                                <div class="line1"></div>
                                <div class="line1"></div>
                                {/* <div class="line1"></div> */}
                            </div>
                            <span className='greengradient1'></span>
                            <span className='violentgradient'></span>
                            <img className='img-fluid crossline' src={crossline} alt='alirobo' />
                            <img className='img-fluid ellipse' src={ellipse} alt='alirobo' />
                            <img className='img-fluid polygon' src={polygon} alt='alirobo' />
                            <img className='img-fluid polygon2' src={polygon2} alt='alirobo' />
                            <img className='img-fluid leftside' src={leftside} alt='alirobo' />
                            <div className='row align-items-center'>
                                <div className='col-md-6 mt-3'>
                                    <OwlCarousel className='owl-theme' 
                                    {...options}
                                    responsive={responsive1} 
                                    >
                                        {aboutdata && aboutdata.length>0?aboutdata.map((val,index)=>(
                                                <div class='item'>
                                                <div>
                                                    <button type="button" class="btn userbtn headingbtn" data-aos="fade-right" data-aos-duration="1000">{val.Title}</button>
                                                    {/* <h2 className='mt-3 title' data-aos="fade-right" data-aos-duration="1500">A Blockchain Protocol Designed for the</h2> */}
                                                    <div className='mt-3 titlelist'>
                                                        <Typed
                                                            strings={[val && val.Heading ? val.Heading : "A Blockchain check"]}
                                                            typeSpeed={130}
                                                            cursorChar={"_"}
                                                            loop
                                                        />
                                                    </div>
                                                    <h2 className='mt-0 title title-sub' data-aos="fade-right" data-aos-duration="1000"><span className='titlespan'>{val.Description} </span></h2>
    
                                                    <div className='backimg' data-aos="fade-down" data-aos-duration="1500">
                                                        <p className='mb-0 texts'>{val.content}</p>
                                                    </div>
                                                </div>
                                            </div> 
                                        )):''}
                                        {/* <div class='item'>
                                            <div>
                                                <button type="button" class="btn userbtn headingbtn" data-aos="fade-right" data-aos-duration="1000">{aboutdata[0]?.Title}</button>
                    
                                                <div className='mt-3 titlelist'>
                                                    <Typed
                                                        strings={[aboutdata && aboutdata[0]?.Heading ? aboutdata[0]?.Heading : "A Blockchain check"]}
                                                        typeSpeed={130}
                                                        cursorChar={"_"}
                                                        loop
                                                    />
                                                </div>
                                                <h2 className='mt-0 title title-sub' data-aos="fade-right" data-aos-duration="1000"><span className='titlespan'>{aboutdata[0]?.Description} </span></h2>

                                                <div className='backimg' data-aos="fade-down" data-aos-duration="1500">
                                                    <p className='mb-0 texts'>{aboutdata[0]?.content}</p>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div class='item'>
                                            <div>
                                                <button type="button" class="btn userbtn headingbtn">{aboutdata[1]?.Title}</button>
                                                
                                                <div className='mt-3 titlelist'>
                                                    <Typed
                                                        strings={[aboutdata && aboutdata[1]?.Heading ? aboutdata[1]?.Heading : "A Blockchain check"]}
                                                          typeSpeed={130}
                                                          cursorChar={"_"}
                                                        loop
                                                    />
                                                </div>
                                                <h2 className='mt-0 title title-sub'><span className='titlespan'>{aboutdata[1]?.Description}</span></h2>
                                                <div className='backimg mt-2'>
                                                    <p className='mb-0 texts'>{aboutdata[1]?.content}</p>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div class='item'>
                                            <div>
                                                <button type="button" class="btn userbtn headingbtn">{aboutdata[2]?.Title}</button>
                                               
                                                <div className='mt-3 titlelist'>
                                                    <Typed
                                                        strings={[aboutdata && aboutdata[2]?.Heading ? aboutdata[2]?.Heading : "A Blockchain check"]}
                                                          typeSpeed={130}
                                                          cursorChar={"_"}
                                                        loop
                                                    />
                                                </div>
                                                <h2 className='mt-0 title title-sub'><span className='titlespan'>{aboutdata[2]?.Description}</span></h2>
                                                <div className='backimg'>
                                                    <p className='mb-0 texts'>{aboutdata[2]?.content}</p>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div class='item'>
                                            <div>
                                                <button type="button" class="btn userbtn headingbtn">{aboutdata[3]?.Title}</button>
                                                
                                                <div className='mt-3 titlelist'>
                                                    <Typed
                                                        strings={[aboutdata && aboutdata[3]?.Heading ? aboutdata[3]?.Heading : "A Blockchain check"]}
                                                          typeSpeed={130}
                                                          cursorChar={"_"}
                                                        loop
                                                    />
                                                </div>
                                                <h2 className='mt-0 title title-sub'><span className='titlespan'>{aboutdata[3]?.Description} </span></h2>
                                                <div className='backimg'>
                                                    <p className='mb-0 texts'>{aboutdata[3]?.content}</p>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div class='item'>
                                            <div>
                                                <button type="button" class="btn userbtn headingbtn">{aboutdata[4]?.Title}</button>
                                               
                                                <div className='mt-3 titlelist'>
                                                    <Typed
                                                        strings={[aboutdata && aboutdata[4]?.Heading ? aboutdata[4]?.Heading : "A Blockchain check"]}
                                                          typeSpeed={130}
                                                          cursorChar={"_"}
                                                        loop
                                                    />
                                                </div>
                                                <h2 className='mt-0 title title-sub'><span className='titlespan'>{aboutdata[4]?.Description}</span></h2>
                                                <div className='backimg'>
                                                    <p className='mb-0 texts'>{aboutdata[4]?.content}</p>
                                                </div>
                                            </div>
                                        </div> */}
                                    </OwlCarousel>
                                </div>
                                <div className='col-md-6 mt-3' 
                                // data-aos="fade-up"  src={`${backurl}/Images/${featuredata[0]?.logoURI}`} alt='alirobo' />
                                >
                                    {/* <img className='img-fluid yy' src={robo} alt='alirobo' /> */}
                                    {/* <img className='img-fluid yy' src={  `${backurl}/Images/${aboutdata[0]?.logoURI}` } alt='alirobo' /> */}
                                       {Homeimage && Homeimage[0]?.logoURI == "" ? <img className='img-fluid yy' src={robo} alt='alirobo' /> :<img className='img-fluid yy' src={  `${backurl}/Images/${Homeimage[0]?.logoURI}` } alt='alirobo' />}

                                </div>
                            </div>
                        </section>

                        <section className='userapp'>
                        <div class="lines">
                                <div class="line1"></div>
                                <div class="line1" style={{ display:"none" }}></div>
                                <div class="line1"></div>
                                {/* <div class="line1"></div> */}
                            </div>
                            <div className='row align-items-center'>
                                <div className='col-lg-6 col-md-12 mt-5' data-aos="fade-down" data-aos-duration="1000">
                                    <div className='backimg1'>
                                        <div className='row align-items-center'>
                                            <div className='col-md-5 col-sm-3'>
                                                {/* <img className='img-fluid defrobo' src={downrobo} alt='alirobo' /> */}
                                                {/* <img className='img-fluid defrobo' src={`${backurl}/Images/${aboutdata[5]?.logoURI}`}  alt='alirobo' /> */}
                                                {Homepage && Homepage[0]?.logoURI == "" ? <img className='img-fluid defrobo' src={downrobo} alt='alirobo' /> : <img className='img-fluid defrobo' src={`${backurl}/Images/${Homepage[0]?.logoURI}`}  alt='alirobo' />}

                                                <span className='greengradient'></span>
                                            </div>
                                            <div className='col-md-12 col-sm-9 lefttext'>
                                                {/* <h4 data-aos="fade-right" data-aos-duration="1000"><span className='titlespan'>Lor</span>em Ipsum is simply</h4> */}
                                                <h4 data-aos="fade-right" data-aos-duration="1000"><span className='titlespan'></span>{Homepage[0]?.Title}</h4>

                                                <p data-aos="fade-right" data-aos-duration="1000">{Homepage[0]?.Heading}</p>
                                                {/* <div className='mb-3 titledr'>
                                                    <Typed
                                                        strings={['simply dummy Lorem']}
                                                        typeSpeed={40}
                                                        loop
                                                    />
                                                </div> */}
                                                <p className='sub-text mb-0' data-aos="fade-right" data-aos-duration="1000">{Homepage[0]?.Description}</p>
                                                <button type="button" class="btn userbtn useappbtn">{Homepage[0]?.content}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-12 mt-5' data-aos="fade-down" data-aos-duration="1000">
                                    <div className='backimg1'>
                                        <div className='row align-items-center'>
                                            <div className='col-md-5 col-sm-3 text-center'>
                                                {/* <img className='img-fluid defrobo' src={lightrobo} alt='alirobo' />    */}
                                                {/* <img className='img-fluid defrobo' src={`${backurl}/Images/${aboutdata[6]?.logoURI}`} alt='alirobo' />    */}

                                                {Homepage && Homepage[1]?.logoURI=="" ? <img className='img-fluid defrobo' src={lightrobo} alt='alirobo' /> :  <img className='img-fluid defrobo' src={`${backurl}/Images/${Homepage[1]?.logoURI}`} alt='alirobo' />   }

                                                <span className='greengradient'></span>
                                            </div>
                                            <div className='col-md-12 col-sm-9 lefttext'>
                                                <h4 data-aos="fade-right" data-aos-duration="1000"><span className='titlespan'></span>{Homepage[1]?.Title}</h4>
                                                <p data-aos="fade-right" data-aos-duration="1000">{Homepage[1]?.Heading}</p>
                                                {/* <div className='mb-3 titledr'>
                                                    <Typed
                                                        strings={['simply dummy Lorem']}
                                                        typeSpeed={40}
                                                        loop
                                                    />
                                                </div> */}
                                                <p className='sub-text mb-0' data-aos="fade-right" data-aos-duration="1500">{Homepage[1]?.Description}</p>
                                                <button type="button" class="btn userbtn useappbtn">{Homepage[1]?.content}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className='banner'>
                        <div class="lines">
                                <div class="line1"></div>
                                <div class="line1"></div>
                                <div class="line1"></div>
                                {/* <div class="line1"></div> */}
                            </div>
                            <span className='greengradientlefts'></span>
                            <img className='img-fluid crossline1' src={crossline} alt='alirobo' />
                            <img className='img-fluid circle' src={circle} alt='alirobo' />
                            <img className='img-fluid greenrectangle' src={greenrectangle} alt='alirobo' />
                            <img className='img-fluid ployright' src={ploylight} alt='alirobo' />
                            <span className='greengradient2'></span>
                            <div className='row align-items-center'>
                                <div className='col-md-6 mt-4 mb-4' data-aos="fade-up">
                                    {/* <img className='img-fluid roboo yy' src={`${backurl}/Images/${featuredata[0]?.logoURI}`} alt='alirobo' /> */}
                                    {/* <img className='img-fluid roboo yy' src={coinrobo} alt='alirobo' /> */}
                                    {featuredata && featuredata[0]?.logoURI == "" ? <img className='img-fluid roboo yy' src={coinrobo} alt='alirobo' />:  <img className='img-fluid roboo yy' src={`${backurl}/Images/${featuredata[0]?.logoURI}`} alt='alirobo' />}
                                </div>
                                <div className='col-md-6 mt-4'>
                                    <div>
                                        <button type="button" class="btn userbtn headingbtn" 
                                        // data-aos="fade-left" data-aos-duration="1500"
                                        >{featuredata[0]?.Title}</button>
                                        {/* <h2 className='mt-3 title'>A Blockchain Protocol Designed for the</h2> */}
                                        <div className='mt-3 titlelist'>
                                            <Typed
                                                strings={[featuredata && featuredata[0]?.Heading ? featuredata[0]?.Heading : "A Blockchain check"]}
                                                typeSpeed={130}
                                                cursorChar={"_"}
                                                loop
                                            />
                                        </div>
                                        <h2 className='mt-0 title title-sub' 
                                        // data-aos="fade-left" data-aos-duration="1500"
                                        ><span className='titlespan'>{featuredata[0]?.Description}  </span> </h2>
                                        <div className='backimg' 
                                        data-aos="fade-up" 
                                        // data-aos-duration="1500"
                                        >
                                            <p className='mb-0 texts'>{featuredata[0]?.content}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className='banner bannerlast'>
                        <div class="lines">
                                <div class="line1"></div>
                                <div class="line1"></div>
                                <div class="line1"></div>
                                {/* <div class="line1"></div> */}
                            </div>
                            <span className='greengradientround'></span>
                            <img className='img-fluid leftline' src={lastline} alt='alirobo' />
                            <div className='row align-items-center'>
                                <div className='col-md-6 mt-4'>
                                    <div>
                                        <button type="button" class="btn userbtn headingbtn" 
                                        // data-aos="fade-right" data-aos-duration="1500"
                                        >{featuredata[1]?.Title}</button>
                                        {/* <h2 className='mt-3 title'>A Blockchain Protocol Designed for the</h2> */}
                                        <div className='mt-3 titlelist'>
                                            <Typed
                                                strings={['A Blockchain Protocol Designed for the']}
                                                typeSpeed={130}
                                                cursorChar={"_"}
                                                loop
                                            />
                                        </div>
                                        <h2 className='mt-0 title title-sub' 
                                        // data-aos="fade-right" data-aos-duration="1500"
                                        ><span className='titlespan'>{featuredata[1]?.Description}</span> </h2>
                                        <div className='backimg mt-3' 
                                     data-aos="fade-up" 
                                    //  data-aos-duration="1500"
                                        >
                                            <p className='mb-0 texts'>{featuredata[1]?.content}</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-md-6 mt-4' 
                                data-aos="fade-up" 
                                // data-aos-duration="1500"
                                >
                                   
                                   { featuredata && featuredata[1]?.logoURI == "" ? <img className='img-fluid roboo' src={sitrobo} alt='alirobo' /> :  <img className='img-fluid roboo' src={`${backurl}/Images/${featuredata[1]?.logoURI}`}alt='alirobo' />}
                                    {/* <img className='img-fluid roboo' src={`${backurl}/Images/${featuredata[1]?.logoURI}`}alt='alirobo' /> */}
                                </div>

                            </div>
                        </section>

                        <section className='banner'>
                        <div class="lines">
                                <div class="line1"></div>
                                <div class="line1"></div>
                                <div class="line1"></div>
                                {/* <div class="line1"></div> */}
                            </div>
                            <span className='greengradientleft'></span>
                            <span className='greengradientright'></span>
                            <span className='greengradientcenter'></span>
                            <img className='img-fluid circle circle1' src={circle} alt='alirobo' />
                            <img className='img-fluid lastline' src={lastline} alt='alirobo' />
                            <div className='text-center'>
                                <button type="button" class="btn userbtn headingbtn">{featuredesdata[0]?.Title}</button>
                                {/* <h2 className='mt-3 title'>A Blockchain Protocol Designed for the</h2> */}
                                <div className='mt-3 titlelist'>
                                    <Typed
                                        strings={[featuredesdata && featuredesdata[0]?.Heading ? featuredesdata[0]?.Heading : "A Blockchain check"]}
                                        typeSpeed={130}
                                        cursorChar={"_"}
                                        loop
                                    />
                                    
                                </div>
                                <h2 className='mt-0 title title-sub'><span className='titlespan'>{featuredesdata[0]?.Description}</span></h2>
                            </div>
                            <div>
                                <div className='row mt-2'>
                                    <div className='col-md-6 mt-5' data-aos="fade-up" data-aos-duration="1500">
                                        <div className='row'>
                                            <div className='col-md-6 mt-3 mb-3'>
                                                <div className='cardbreakimg'>
                                                    <span className='greengradient'></span>
                                                    {
                                                        roadmapdata && roadmapdata[0]?.logoURI == "" ?<img className='img-fluid roboo1' src={robos} alt='alirobo' />  : <img className='img-fluid roboo1' src={`${backurl}/Images/${roadmapdata[0]?.logoURI}`} alt='alirobo' />
                                                    }
                                                    <button type="button" class="btn nobtn noappbtn">1</button>
                                                </div>
                                            </div>
                                            <div className='col-md-6 text-center mt-3 mb-3'>
                                                <p className='mb-0 lasttext'>{roadmapdata[1]?.Title}</p>
                                                <h5><span className='titlespan'>{roadmapdata[1]?.Heading}</span></h5>
                                                <p className='aitext mt-3 mb-4'>{roadmapdata[1]?.Description}</p>
                                                <button type="button" class="btn userbtn">{roadmapdata[1]?.content}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mt-5' data-aos="fade-up" data-aos-duration="1500">
                                        <div className='row'>
                                            <div className='col-md-6 mt-3 mb-3'>
                                                <div className='cardbreakimg'>
                                                    <span className='greengradient'></span>
                                           

                                                    {roadmapdata && roadmapdata[2]?.logoURI =="" ?  <img className='img-fluid roboo1' src={alien} alt='alirobo' /> :  <img className='img-fluid roboo1'     src={`${backurl}/Images/${roadmapdata[2]?.logoURI}`} /> 

                                                    }
                                                    <button type="button" class="btn nobtn noappbtn">3</button>
                                                </div>
                                            </div>
                                            <div className='col-md-6 text-center mt-3 mb-3'>
                                                <p className='mb-0 lasttext'>{roadmapdata[3]?.Title}</p>
                                                <h5><span className='titlespan'>{roadmapdata[3]?.Heading}</span> </h5>
                                                <p className='aitext mt-3 mb-4'>{roadmapdata[3]?.Description}</p>
                                                <button type="button" class="btn userbtn">{roadmapdata[3]?.content}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mt-5' data-aos="fade-up" data-aos-duration="1500">
                                        <div className='row'>
                                            <div className='col-md-6 text-center mt-3 mb-3'>
                                                <p className='mb-0 lasttext'>{roadmapdata[0]?.Title}</p>
                                                <h5><span className='titlespan'>{roadmapdata[0]?.Heading}</span></h5>
                                                <p className='aitext mt-3 mb-4'>{roadmapdata[0]?.Description}</p>
                                                <button type="button" class="btn userbtn">{roadmapdata[0]?.content}</button>
                                            </div>
                                            <div className='col-md-6 mt-3 mb-3'>
                                                <div className='cardbreakimg'>
                                                    <span className='greengradient'></span>
                                    
                                                    {
                                                        roadmapdata && roadmapdata[1]?.logoURI == "" ? <img className='img-fluid roboo1' src={siderobo} alt='alirobo' /> : <img className='img-fluid roboo1' src={`${backurl}/Images/${roadmapdata[1]?.logoURI}`} alt='alirobo' />
                                                    }

                                                    <button type="button" class="btn nobtn noappbtn">2</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mt-5' data-aos="fade-up" data-aos-duration="1500">
                                        <div className='row'>
                                            <div className='col-md-6 text-center mt-3 mb-3'>
                                                <p className='mb-0 lasttext'>{roadmapdata[2]?.Title}</p>
                                                <h5><span className='titlespan'>{roadmapdata[2]?.Heading}</span></h5>
                                                <p className='aitext mt-3 mb-4'>{roadmapdata[2]?.Description}</p>
                                                <button type="button" class="btn userbtn">{roadmapdata[2]?.content}</button>
                                            </div>
                                            <div className='col-md-6 mt-3 mb-3'>
                                                <div className='cardbreakimg'>
                                                    <span className='greengradient'></span>
                                 
                                                    {
                                                        roadmapdata && roadmapdata[3]?.logoURI == "" ? <img className='img-fluid roboo1' src={leftrobo} alt='alirobo' /> : <img className='img-fluid roboo1' src={`${backurl}/Images/${roadmapdata[3]?.logoURI}`} alt='alirobo' />
                                                    }

                                                    <button type="button" class="btn nobtn noappbtn">4</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>

                <Footer />
            </div>

        </>
    )


}

export default Home;  